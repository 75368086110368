<template>
  <div class="example-item" @click="$emit('click', item)">
    <div class="example-item-icon">
      <div class="example-item-icon__image">
        <img
          :src="imgUrl"
          :style="{
            paddingLeft: item.icon.padding + 'px',
            paddingRight: item.icon.padding + 'px',
          }"
        />
      </div>
    </div>
    <div class="example-item__label">{{ item.label }}</div>
    <div class="example-item__sublabel">{{ item.sublabel }}</div>
  </div>
</template>

<script>
export default {
  name: "ExampleItem",
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    imgUrl() {
      return `/images/icon-example-${this.item.icon.icon}.svg`;
    },
  },
};
</script>
<style>
.example-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-bottom: 30px;
  cursor: pointer;
}
.example-item-icon {
  width: 112px;
  height: 112px;
  background-color: #fff;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.example-item-icon__image {
  width: 60px;
}
.example-item-icon__image img {
  width: 100%;
}
.example-item__label {
  color: #00b17a;
  font-weight: bold;
  text-transform: uppercase;
  margin: 14px 0 2px 0;
  text-align: center;
}
.example-item__sublabel {
  color: #00b17a;
}
@media screen and (min-width: 768px) {
  .example-item {
    width: 33%;
  }
}
@media screen and (min-width: 1200px) {
  .example-item {
    width: 25%;
  }
}
</style>
