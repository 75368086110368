<template>
  <div class="print-panel">
    <h2 class="print-panel-header">
      <img class="print-panel-header__icon" :src="headerIconSrc" />
      <span class="print-panel-header__label">{{ header }}</span>
    </h2>

    <h3 class="print-panel-headline">{{ headline }}</h3>

    <ul class="print-panel-items">
      <li
        v-for="(item, index) in items"
        :key="`print-panel-item-${index}`"
        class="print-panel-item"
      >
        <div class="print-panel-item__name">
          {{ itemName(item) }}
        </div>
        <div class="print-panel-item__kilo">
          {{ formatNumber(item.result, locale, dictionary.kilo) }}
        </div>
        <div class="print-panel-item__volume">
          {{ volumeLabel(item) }}
        </div>
        <div class="print-panel-item__moisture">
          {{ `${item.moisture}${dictionary.woodaddmoisturepercent}` }}
        </div>
        <div class="print-panel-item__co2">
          {{ formatNumber(item.co2, locale, dictionary.kilo) }}
        </div>
      </li>
      <li class="print-panel-item print-panel-item--result">
        <div class="print-panel-item__name">
          {{ dictionary.total }}
        </div>
        <div class="print-panel-item__co2">
          {{ `${formatNumber(co2, locale, dictionary.kilo)} CO₂` }}
        </div>
      </li>
    </ul>

    <div class="print-panel-comparisons">
      <print-comparison
        v-for="(comparison, index) in comparisons"
        :key="`print-comparison-${index}`"
        :comparison="comparison"
        :co2="co2"
        :locale="locale"
        :dictionary="dictionary"
      />
    </div>
    <div class="print-panel-fact">
      <img class="print-panel-fact__icon" src="images/icon-tree--green.svg" />
      <div class="print-panel-fact__content">{{ dictionary.resultfact }}</div>
    </div>
  </div>
</template>

<script>
import { formatNumberWithPostfix } from "@/utils/formatter";
import PrintComparison from "@/components/PrintComparison";

export default {
  name: "PrintPanel",
  components: { PrintComparison },
  props: {
    headline: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    example: {
      type: Object,
      default: null,
    },
    comparisons: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dictionary: {
      type: Object,
      default: function () {
        return {};
      },
    },
    co2: {
      type: Number,
      default: 0,
    },
    locale: {
      type: String,
      default: "da-DK",
    },
  },
  computed: {
    header() {
      return this.example ? this.example.label : this.dictionary.resultheader;
    },
    headerIconSrc() {
      return this.example
        ? `images/icon-example-${this.example.icon.icon}--black.svg`
        : "images/icon-ruler.svg";
    },
  },
  methods: {
    itemName(item) {
      return item.wood && item.wood.name.length > 0
        ? item.wood.name
        : this.dictionary.unknownwood;
    },
    formatNumber(value, locale, postfix) {
      return formatNumberWithPostfix(value, locale, postfix);
    },
    volumeLabel(item) {
      return item.volume
        ? this.formatNumber(item.volume, this.locale, this.dictionary.mcubed)
        : `- ${this.dictionary.mcubed}`;
    },
  },
};
</script>

<style>
.print-panel {
  display: none;
}

@media print {
  .print-panel {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-color: #fff;
  }

  .print-panel-header {
    border-bottom: 2px solid #000;
    padding-bottom: 1em;
  }
  .print-panel-header__icon {
    vertical-align: middle;
    max-width: 26px;
  }
  .print-panel-header__label {
    margin-left: 0.8em;
  }
  .print-panel-headline {
    color: #00b17a;
    text-align: center;
  }

  .print-panel-items {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .print-panel-item {
    border-bottom: 1px solid #00b17a;
    position: relative;
    padding: 1em 0;
    display: flex;
    color: #00b17a;
  }
  .print-panel-item__name {
    font-weight: bold;
  }
  .print-panel-item__volume {
    position: absolute;
    right: 70%;
    bottom: 1em;
  }
  .print-panel-item__kilo {
    position: absolute;
    right: 50%;
    bottom: 1em;
  }
  .print-panel-item__moisture {
    position: absolute;
    right: 30%;
    bottom: 1em;
  }
  .print-panel-item__co2 {
    position: absolute;
    right: 0;
    bottom: 1em;
    font-weight: bold;
  }
  .print-panel-item.print-panel-item--result {
    font-size: 18px;
    border-bottom: 2px solid #00b17a;
  }
  .print-panel-comparisons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3em;
  }
  .print-panel-fact {
    position: relative;
    border: 1px solid #00b17a;
    margin-top: 50px;
    padding: 10px 10px 10px 80px;
    color: #00b17a;
  }
  .print-panel-fact__icon {
    position: absolute;
    width: 40px;
    top: 15px;
    left: 15px;
  }
}
</style>
