<template>
  <div id="app" v-if="loaded">
    <transition :name="transitionName"><router-view></router-view></transition>
    <transition name="slide-up-down">
      <result-panel
        v-if="isResultPanelVisible"
        :items="items"
        :example="currentExample"
        :comparisons="comparisons"
        :dictionary="dictionary"
        :locale="settings.locale"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ResultPanel from "@/components/ResultPanel";

export default {
  name: "App",
  components: { ResultPanel },
  data() {
    return {
      transitionName: "slide-forward",
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    $route(to) {
      // Sets transition name based on which view is the next
      if (to.name === "Wood" || to.name === "Examples") {
        this.transitionName = "slide-forward";
      } else if (to.name === "Home") {
        this.transitionName = "slide-back";
      } else {
        this.transitionName = null;
      }
    },
  },
  computed: {
    isResultPanelVisible() {
      return this.$route.query.mode && this.$route.query.mode === "result";
    },

    ...mapState([
      "loaded",
      "settings",
      "dictionary",
      "items",
      "currentExample",
      "comparisons",
    ]),
  },
  methods: {
    ...mapActions(["loadData"]),
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  height: calc(100vh - env(safe-area-inset-bottom));
}
a {
  text-decoration: none;
}
#app {
  font-family: sans-serif;
  height: 100%;
  overflow: hidden;
}

#app .slide-forward-enter-active,
#app .slide-forward-leave-active {
  transition: left 0.2s cubic-bezier(0.33, 1, 0.68, 1);
}

#app .slide-forward-enter-to {
  left: 0;
}

#app .slide-forward-enter {
  left: 100%;
}

#app .slide-forward-leave-to {
  left: -30%;
}

#app .slide-forward-leave {
  left: 0;
}

#app .slide-back-enter-active,
#app .slide-back-leave-active {
  transition: left 0.2s cubic-bezier(0.33, 1, 0.68, 1);
}

#app .slide-back-enter-to {
  left: 0;
}

#app .slide-back-enter {
  left: -30%;
}

#app .slide-back-leave-to {
  z-index: 1;
  left: 100%;
}

#app .slide-back-leave {
  z-index: 1;
  left: 0;
}
#app .slide-up-down-enter-active,
#app .slide-up-down-leave-active {
  transition: bottom 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}

#app .slide-up-down-enter-to {
  bottom: 0;
}

#app .slide-up-down-enter {
  bottom: -120%;
}

#app .slide-up-down-leave-to {
  bottom: -120%;
}

#app .slide-up-down-leave {
  bottom: 0;
}
</style>
