<template>
  <div class="co2-result">
    {{ kilo }}
    <img class="co2-result__icon" src="images/co2-result-icon.svg" />
  </div>
</template>

<script>
export default {
  name: "Co2Result",
  props: {
    kilo: {
      type: String,
      default: "",
    },
    dictionary: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {},
  methods: {},
};
</script>

<style>
.co2-result {
  background-color: #fff;
  color: #00b17a;
  font-size: 15px;
  font-weight: bold;
  border-radius: 15px;
  padding: 5px 8px;
}

.co2-result__icon {
  position: absolute;
  width: 26px;
  right: -16px;
  top: -10px;
}
</style>
