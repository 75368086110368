var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wood-add-form"},[_c('div',{staticClass:"wood-add-form__header"},[_vm._v(_vm._s(_vm.dictionary.woodaddformheader))]),_c('div',{staticClass:"wood-add-form__content"},[_c('div',{staticClass:"wood-add-form__row"},[_c('div',{staticClass:"wood-add-form__col"},[_c('unit-toggle',{attrs:{"dictionary":_vm.dictionary,"active":_vm.unit},on:{"toggle":_vm.unitToggle}}),_c('popper',{attrs:{"options":{
            placement: 'left',
            modifiers: { offset: { offset: '0,2-px' } },
          }}},[_c('div',{staticClass:"popper"},[_vm._v(" "+_vm._s(_vm.dictionary.tooltiptoggle)+" ")]),_c('span',{staticClass:"popper-trigger",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("?")])])],1)]),_c('div',{staticClass:"wood-add-form__row"},[_c('div',{staticClass:"wood-add-form__col"},[_c('multiselect',{attrs:{"options":_vm.woods,"allow-empty":false,"select-label":"","deselect-label":"","selected-label":"","placeholder":_vm.dictionary.woodaddchoosespecies,"custom-label":_vm.label},on:{"input":_vm.updateValue},model:{value:(_vm.wood),callback:function ($$v) {_vm.wood=$$v},expression:"wood"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.dictionary.woodaddnoresult))])],2)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.unit === 'volume'),expression:"unit === 'volume'"}],staticClass:"wood-add-form__row"},[_c('div',{staticClass:"wood-add-form__col wood-add-form__col--tooltip"},[_c('text-input',{attrs:{"placeholder":_vm.dictionary.quantity,"unit":_vm.dictionary.mcubed},on:{"input":function($event){return _vm.updateValue()}},model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=$$v},expression:"volume"}}),_c('popper',{attrs:{"options":{
            placement: 'left',
            modifiers: { offset: { offset: '0,2-px' } },
          }}},[_c('div',{staticClass:"popper"},[_vm._v(" "+_vm._s(_vm.dictionary.tooltipvolume)+" ")]),_c('span',{staticClass:"popper-trigger",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("?")])])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.unit === 'weight'),expression:"unit === 'weight'"}],staticClass:"wood-add-form__row"},[_c('div',{staticClass:"wood-add-form__col wood-add-form__col--tooltip"},[_c('text-input',{attrs:{"placeholder":_vm.dictionary.quantity,"unit":_vm.dictionary.kilo},on:{"input":function($event){return _vm.updateValue()}},model:{value:(_vm.kilo),callback:function ($$v) {_vm.kilo=$$v},expression:"kilo"}}),_c('popper',{attrs:{"options":{
            placement: 'left',
            modifiers: { offset: { offset: '0,2-px' } },
          }}},[_c('div',{staticClass:"popper"},[_vm._v(" "+_vm._s(_vm.dictionary.tooltipweight)+" ")]),_c('span',{staticClass:"popper-trigger",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("?")])])],1)]),_c('div',{staticClass:"wood-add-form__row"},[_c('div',{staticClass:"wood-add-form__col wood-add-form__col--tooltip"},[_c('text-input',{attrs:{"placeholder":_vm.dictionary.woodaddmoisturedefault,"unit":_vm.dictionary.woodaddmoisturepercent},on:{"input":function($event){return _vm.updateValue()}},model:{value:(_vm.moisture),callback:function ($$v) {_vm.moisture=$$v},expression:"moisture"}}),_c('popper',{attrs:{"options":{
            placement: 'left',
            modifiers: { offset: { offset: '0,2-px' } },
          }}},[_c('div',{staticClass:"popper"},[_vm._v(_vm._s(_vm.dictionary.tooltipmoisture))]),_c('span',{staticClass:"popper-trigger",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("?")])])],1)]),_c('div',{staticClass:"wood-add-form__row"},[_c('div',{staticClass:"wood-add-form__col"},[_c('simple-button-panel',[_c('router-link',{attrs:{"to":"/wood"}},[_c('simple-button',{attrs:{"label":_vm.dictionary.cancel,"link":"","invert":""}})],1),_c('simple-button',{attrs:{"label":_vm.dictionary.ok,"disabled":!_vm.isFormValid},on:{"click":_vm.okClick}})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }