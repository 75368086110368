<template>
  <div class="home">
    <div class="home-container">
      <h1 class="home-header">{{ dictionary.homeheader }}</h1>
      <p class="home-description">{{ dictionary.homedescription }}</p>

      <div class="home-buttons">
        <router-link to="/wood">
          <home-button
            :label="dictionary.homebuttontry"
            icon="ruler--white"
            invert
          />
        </router-link>

        <router-link to="/examples">
          <home-button
            :label="dictionary.homebuttonexamples"
            icon="seedling--green"
          />
        </router-link>
      </div>

      <lottie-animation path="co2animation/co2-animation.json" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "../../node_modules/lottie-vuejs/src/LottieAnimation.vue";
import HomeButton from "@/components/HomeButton";

export default {
  name: "Home",
  components: { LottieAnimation, HomeButton },
  data() {
    return {};
  },
  computed: {
    ...mapState(["dictionary"]),
  },
};
</script>

<style>
.home {
  padding: 20px;
  background-color: #f2fffc;
  background-image: url("/images/bg-home.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% auto;
  padding-bottom: 100px;
  min-height: 100%;
}
.home-container {
  max-width: 800px;
  margin: 0 auto;
}
.home-header {
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
}
.home-description {
  font-size: 18px;
  line-height: 1.4em;
  max-width: 600px;
  margin: 0 auto;
}
.home-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .home-buttons {
    margin-top: 40px;
    flex-direction: row;
  }
}
</style>
