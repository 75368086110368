<template>
  <div class="print-comparison">
    <div class="print-comparison__image">
      <img :src="iconSrc" />
    </div>
    <div class="print-comparison__text" v-html="text" />
  </div>
</template>

<script>
import { formatNumberWithPostfix } from "@/utils/formatter";

export default {
  name: "PrintComparison",
  components: {},
  props: {
    dictionary: {
      type: Object,
      default: function () {
        return {};
      },
    },
    locale: {
      type: String,
      default: "da-DK",
    },
    comparison: {
      type: Object,
      default: function () {
        return {};
      },
    },
    co2: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    iconSrc() {
      return `images/icon-${this.comparison.icon}.svg`;
    },
    text() {
      return this.comparison.text.replace(
        "¿",
        formatNumberWithPostfix(this.result, this.locale)
      );
    },
    result() {
      const value = this.comparison.factor * this.co2;
      let result = 0;
      if (value < 1) {
        result = value;
      } else {
        result = Math.floor(value);
      }
      return result;
    },
  },
};
</script>

<style>
.print-comparison {
  color: #000;
  text-align: center;
  padding: 0 30px;
  width: 33%;
}
.print-comparison__image {
  width: 100%;
  height: 160px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.print-comparison__image img {
  width: 100%;
  height: auto;
  max-height: 140px;
}
.print-comparison__text {
  font-size: 1em;
}
</style>
