<template>
  <div :class="classes" @click="$emit('click')">
    <div v-if="icon" class="simple-button__col">
      <img class="simple-button__icon" :src="iconUrl" />
    </div>
    <div class="simple-button__col">
      <span class="simple-button__label">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleButton",
  props: {
    label: { type: String, default: "" },
    icon: { type: String, default: null },
    invert: { type: Boolean, default: false },
    link: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return {
        "simple-button": true,
        "simple-button--invert": this.invert,
        "simple-button--link": this.link,
        "simple-button--disabled": this.disabled,
      };
    },
    iconUrl() {
      return `/images/icon-${this.icon}.svg`;
    },
  },
};
</script>
<style>
.simple-button {
  display: inline-flex;
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  color: #00b17a;
  padding: 0 22px 0 22px;
  border-radius: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  height: 50px;
  text-transform: uppercase;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}
.simple-button__col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.simple-button.simple-button--invert {
  background-color: #00b17a;
  color: #fff;
}
.simple-button.simple-button--link {
  background: none !important;
  box-shadow: none;
}
.simple-button.simple-button--disabled {
  opacity: 0.5;
  cursor: default;
  box-shadow: none;
}
.simple-button__icon {
  margin: 0 10px 0 0;
}
</style>
