<template>
  <div class="back-button">
    <img class="back-button__arrow" src="/images/icon-arrowback--green.svg" />{{
      label
    }}
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: { label: { type: String, default: "" } },
};
</script>

<style>
.back-button {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #bde6da;
  white-space: nowrap;
  text-transform: uppercase;
}
.back-button__arrow {
  margin: 0 20px;
}
</style>
