<template>
  <div class="result-panel">
    <h2 class="result-panel-header">
      <img class="result-panel-header__icon" :src="headerIconSrc" />
      <div class="result-panel-header__label">{{ header }}</div>
    </h2>
    <router-link :to="$route.path">
      <img class="result-panel__close" src="images/icon-close--white.svg" />
    </router-link>

    <div class="result-panel__content">
      <h2 class="result-panel-headline">{{ headline }}</h2>
      <wood-result-list
        :items="resultItems"
        :dictionary="dictionary"
        :locale="locale"
        :co2="co2"
      />

      <div class="carousel">
        <carousel
          :perPage="1"
          :perPageCustom="[
            [768, 3],
            [1024, 4],
            [1400, 6],
          ]"
          paginationActiveColor="#fff"
          paginationColor="rgba(255,255,255,0.3"
          :navigation-enabled="true"
        >
          <slide v-for="(comparison, index) in comparisons" :key="index">
            <comparison
              :comparison="comparison"
              :co2="co2"
              :dictionary="dictionary"
              :locale="locale"
            />
          </slide>
        </carousel>
      </div>

      <fact icon="tree--white">
        {{ dictionary.resultfact }}
      </fact>

      <simple-button-panel style="margin-top: 40px">
        <simple-button
          icon="print--white"
          :label="dictionary.print"
          link
          invert
          @click="print"
        />
        <a :href="dictionary.moreinfourl" target="_blank">
          <simple-button
            icon="link--white"
            :label="dictionary.moreinfolabel"
            link
            invert
          />
        </a>
      </simple-button-panel>
    </div>

    <print-panel
      :headline="headline"
      :items="resultItems"
      :example="example"
      :dictionary="dictionary"
      :comparisons="comparisons"
      :co2="co2"
      :locale="locale"
    />
  </div>
</template>

<script>
import { formatNumberWithPostfix } from "../utils/formatter.js";
import { Carousel, Slide } from "vue-carousel";
import SimpleButtonPanel from "@/components/SimpleButtonPanel";
import SimpleButton from "@/components/SimpleButton";
import WoodResultList from "@/components/WoodResultList";
import Comparison from "@/components/Comparison";
import Fact from "@/components/Fact";
import PrintPanel from "@/components/PrintPanel";
export default {
  name: "ResultPanel",
  components: {
    Carousel,
    Slide,
    SimpleButtonPanel,
    SimpleButton,
    WoodResultList,
    Comparison,
    Fact,
    PrintPanel,
  },
  data() {
    return {};
  },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    example: {
      type: Object,
      default: null,
    },
    comparisons: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dictionary: {
      type: Object,
      default: function () {
        return {};
      },
    },
    locale: {
      type: String,
      default: "da-DK",
    },
  },
  computed: {
    resultItems() {
      return this.items.map((item) => {
        const co2 =
          (item.result / (1 + item.moisture / 100)) * (3.666666667 / 2); // Formula for calculating CO2 content in wood
        const co2Rounded = Math.round(co2 * 100) / 100; // Rounded to two decimals
        const result = {
          co2: co2Rounded,
        };

        const resultItem = Object.assign({}, item, result);

        return resultItem;
      });
    },
    header() {
      return this.example ? this.example.label : this.dictionary.resultheader;
    },
    headerIconSrc() {
      return this.example
        ? `images/icon-example-${this.example.icon.icon}--white.svg`
        : "images/icon-ruler--white.svg";
    },
    headline() {
      return this.dictionary.resultheadline.replace(
        "¿",
        formatNumberWithPostfix(this.co2, this.locale, "")
      );
    },
    co2() {
      return this.resultItems.reduce((total, item) => {
        return total + item.co2;
      }, 0);
    },
  },
  methods: {
    print() {
      window.print();
    },
  },
};
</script>

<style>
.result-panel {
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  height: calc(100vh - env(safe-area-inset-bottom));
  background-color: #00b17a;
  box-shadow: 0 -2px 10px rgb(0 0 0 / 24%);
  background-image: url("/images/bg-curve.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100% auto;
}
.result-panel-header {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 20px;
  height: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.result-panel-header__icon {
  display: inline-block;
  max-width: 26px;
  height: auto;
  margin-right: 16px;
}
.result-panel-header__label {
  text-transform: uppercase;
  font-size: 16px;
  color: #fff;
}
.result-panel__close {
  position: absolute;
  right: 20px;
  top: 10px;
}
.result-panel__content {
  overflow-y: auto;
  height: calc(100vh - env(safe-area-inset-bottom) - 60px);
  padding: 30px 0 100px 0;
}
.result-panel-headline {
  text-align: center;
  color: #fff;
  margin-bottom: 5vw;
  margin-top: 0;
}
.result-panel-print {
  display: none;
}

.carousel {
  padding: 0 50px;
  position: relative;
}

.VueCarousel-navigation-button {
  font-size: 30px;
  color: #fff !important;
  outline: none !important;
  background-color: none;
  border: none;
  display: none;
}
.VueCarousel-navigation-prev {
  left: 10px !important;
}
.VueCarousel-navigation-next {
  right: 10px !important;
}
@media screen and (min-width: 768px) {
  .VueCarousel-navigation-button {
    display: block;
  }
}

@media print {
  .result-panel-print {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-color: #fff;
  }

  .result-panel-print-header {
    border-bottom: 2px solid #000;
    padding-bottom: 1em;
  }
  .result-panel-print-header__icon {
    vertical-align: middle;
  }
  .result-panel-print-header__label {
    margin-left: 0.8em;
  }

  .result-panel-print-items {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .result-panel-print-item {
    border-bottom: 1px solid #000;
    position: relative;
    padding: 1em 0;
    display: flex;
  }
  .result-panel-print-item__name {
    font-weight: bold;
  }
  .result-panel-print-item__kilo {
    position: absolute;
    right: 50%;
    bottom: 1em;
  }
  .result-panel-print-item__moisture {
    position: absolute;
    right: 30%;
    bottom: 1em;
  }
  .result-panel-print-item__co2 {
    position: absolute;
    right: 0;
    bottom: 1em;
    font-weight: bold;
  }
  .result-panel-print-item.result-panel-print-item--result {
    font-size: 18px;
    border-bottom: 2px solid #000;
  }
}
</style>
