import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

export const DATA_LOADED = "DATA_LOADED";
export const ITEM_ADD = "ITEM_ADD";
export const ITEM_REMOVE = "ITEM_REMOVE";
export const ITEM_EDIT = "ITEM_EDIT";
export const ITEM_SAVE = "ITEM_SAVE";
export const SET_EXAMPLE = "SET_EXAMPLE";
export const CLEAR_EXAMPLE = "CLEAR_EXAMPLE";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loaded: false,
    dictionary: {},
    settings: {},
    woods: [],
    examples: [],
    currentExample: null,
    items: [],
    currentEditItem: null,
    comparisons: [],
  },
  mutations: {
    [DATA_LOADED](state, value) {
      state.loaded = true;
      state.dictionary = value.dictionary;
      state.settings = value.settings;
      state.woods = value.woods;
      state.examples = value.examples;
      state.comparisons = value.comparisons;
    },
    [ITEM_ADD](state, value) {
      const id = Date.now();
      state.items.push(Object.assign({}, value, { id }));
    },
    [ITEM_REMOVE](state, value) {
      const index = state.items.findIndex((item) => item.id === value.id);
      if (index > -1) {
        state.items.splice(index, 1);
      }
    },
    [ITEM_EDIT](state, value) {
      state.currentEditItem = value;
    },
    [ITEM_SAVE](state, value) {
      const index = state.items.findIndex((item) => item.id === value.id);
      if (index > -1) {
        state.items.splice(index, 1, value);
      }
    },
    [SET_EXAMPLE](state, value) {
      state.currentExample = value;
      state.items = [];
      value.woods.forEach((wood) => {
        state.items.push(wood);
      });
    },
    [CLEAR_EXAMPLE](state) {
      if (state.currentExample) {
        state.currentExample = null;
        state.items = [];
      }
    },
  },
  actions: {
    async loadData({ commit }) {
      try {
        const resp = await axios.get("/data.json");
        commit(DATA_LOADED, resp.data);
      } catch (err) {
        console.error(err);
      }
    },
    addItem({ commit }, payload) {
      commit(ITEM_ADD, payload);
    },
    removeItem({ commit }, payload) {
      commit(ITEM_REMOVE, payload);
    },
    editItem({ commit }, payload) {
      commit(ITEM_EDIT, payload);
    },
    saveItem({ commit }, payload) {
      commit(ITEM_SAVE, payload);
    },
    setExample({ commit }, payload) {
      commit(SET_EXAMPLE, payload);
    },
    clearExample({ commit }) {
      commit(CLEAR_EXAMPLE);
    },
  },
  modules: {},
});
