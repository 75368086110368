<template>
  <div class="text-input">
    <input
      type="text"
      class="text-input__field"
      :placeholder="placeholder"
      :value="value"
      @input="updateValue($event.target.value)"
    />
    <div class="text-input__unit">{{ unit }}</div>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    value: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    unit: { type: String, default: "" },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style>
.text-input {
  position: relative;
  font-size: 18px;
}
.text-input__field {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #00b17a;
  width: 100%;
  font-size: 18px;
  padding: 0 0 0 10px;
}
.text-input__unit {
  position: absolute;
  top: 13px;
  right: 14px;
}
</style>
