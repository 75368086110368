<template>
  <div class="simple-button-panel">
    <slot />
  </div>
</template>

<script>
export default {
  name: "SimpleButtonPanel",
};
</script>
<style>
.simple-button-panel {
  display: flex;
  justify-content: center;
}

.simple-button-panel .simple-button {
  margin-right: 10px;
  margin-left: 10px;
}
</style>
