<template>
  <div class="wood-add-panel">
    <h2 class="wood-add-panel__header">{{ dictionary.woodaddheader }}</h2>

    <div class="wood-add-panel__content">
      <wood-add-form
        :dictionary="dictionary"
        :woods="woods"
        :currentEditItem="currentEditItem"
        :default-moisture="moisture"
        @add-wood="addWood"
        @save-wood="saveWood"
        @show-calculator="showCalculator(true)"
      />
    </div>
  </div>
</template>

<script>
import WoodAddForm from "@/components/WoodAddForm";

export default {
  name: "WoodAddPanel",
  components: { WoodAddForm },
  props: {
    dictionary: {
      type: Object,
      default: function () {
        return {};
      },
    },
    woods: {
      type: Array,
      default: function () {
        return [];
      },
    },
    moisture: { type: Number, default: 0 },
    currentEditItem: { type: Object, default: null },
  },
  computed: {
    isEditMode() {
      return (
        this.$route.query.mode &&
        this.$route.query.mode === "edit" &&
        this.currentEditItem
      );
    },
  },
  methods: {
    addWood(item) {
      this.$emit("add-wood", item);
      this.$router.push({ path: "wood", query: null });
    },
    saveWood(item) {
      this.$emit("save-wood", item);
      this.$router.push({ path: "wood", query: null });
    },
  },
};
</script>

<style>
.wood-add-panel {
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #00b17a;
  box-shadow: 0 -2px 10px rgb(0 0 0 / 24%);
  padding: 5vw 20px;
  overflow-y: auto;
}
.wood-add-panel__header {
  text-transform: uppercase;
  font-size: 22px;
  color: #fff;
  text-align: center;
}
.wood-add-panel__content {
  max-width: 400px;
  margin: 0 auto;
}
</style>
