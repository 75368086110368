<template>
  <div class="wood-add-form">
    <div class="wood-add-form__header">{{ dictionary.woodaddformheader }}</div>
    <div class="wood-add-form__content">
      <div class="wood-add-form__row">
        <div class="wood-add-form__col">
          <unit-toggle
            :dictionary="dictionary"
            :active="unit"
            @toggle="unitToggle"
          /><popper
            :options="{
              placement: 'left',
              modifiers: { offset: { offset: '0,2-px' } },
            }"
          >
            <div class="popper">
              {{ dictionary.tooltiptoggle }}
            </div>

            <span class="popper-trigger" slot="reference">?</span>
          </popper>
        </div>
      </div>
      <div class="wood-add-form__row">
        <div class="wood-add-form__col">
          <multiselect
            v-model="wood"
            :options="woods"
            :allow-empty="false"
            select-label=""
            deselect-label=""
            selected-label=""
            :placeholder="dictionary.woodaddchoosespecies"
            :custom-label="label"
            @input="updateValue"
          >
            <template slot="noResult">{{
              dictionary.woodaddnoresult
            }}</template>
          </multiselect>
        </div>
      </div>
      <div class="wood-add-form__row" v-show="unit === 'volume'">
        <div class="wood-add-form__col wood-add-form__col--tooltip">
          <text-input
            v-model="volume"
            :placeholder="dictionary.quantity"
            :unit="dictionary.mcubed"
            @input="updateValue()"
          /><popper
            :options="{
              placement: 'left',
              modifiers: { offset: { offset: '0,2-px' } },
            }"
          >
            <div class="popper">
              {{ dictionary.tooltipvolume }}
            </div>

            <span class="popper-trigger" slot="reference">?</span>
          </popper>
        </div>
      </div>
      <div class="wood-add-form__row" v-show="unit === 'weight'">
        <div class="wood-add-form__col wood-add-form__col--tooltip">
          <text-input
            v-model="kilo"
            :placeholder="dictionary.quantity"
            :unit="dictionary.kilo"
            @input="updateValue()"
          /><popper
            :options="{
              placement: 'left',
              modifiers: { offset: { offset: '0,2-px' } },
            }"
          >
            <div class="popper">
              {{ dictionary.tooltipweight }}
            </div>

            <span class="popper-trigger" slot="reference">?</span>
          </popper>
        </div>
      </div>
      <div class="wood-add-form__row">
        <div class="wood-add-form__col wood-add-form__col--tooltip">
          <text-input
            v-model="moisture"
            :placeholder="dictionary.woodaddmoisturedefault"
            :unit="dictionary.woodaddmoisturepercent"
            @input="updateValue()"
          /><popper
            :options="{
              placement: 'left',
              modifiers: { offset: { offset: '0,2-px' } },
            }"
          >
            <div class="popper">{{ dictionary.tooltipmoisture }}</div>

            <span class="popper-trigger" slot="reference">?</span>
          </popper>
        </div>
      </div>
      <div class="wood-add-form__row">
        <div class="wood-add-form__col">
          <simple-button-panel>
            <router-link to="/wood">
              <simple-button :label="dictionary.cancel" link invert />
            </router-link>
            <simple-button
              :label="dictionary.ok"
              :disabled="!isFormValid"
              @click="okClick"
            />
          </simple-button-panel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import Multiselect from "vue-multiselect";
import TextInput from "@/components/TextInput";
import UnitToggle from "@/components/UnitToggle";
import SimpleButtonPanel from "@/components/SimpleButtonPanel";
import SimpleButton from "@/components/SimpleButton";

export default {
  name: "WoodAddForm",
  components: {
    Popper,
    Multiselect,
    TextInput,
    UnitToggle,
    SimpleButtonPanel,
    SimpleButton,
  },
  props: {
    dictionary: {
      type: Object,
      default: function () {
        return {};
      },
    },
    defaultMoisture: {
      type: Number,
      default: 0,
    },
    woods: {
      type: Array,
      default: function () {
        return [];
      },
    },
    currentEditItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      wood: null,
      kilo: "",
      volume: "",
      moisture: "",
      unit: "weight",
      current: null,
    };
  },
  mounted() {
    if (this.isEditMode) {
      this.wood = this.currentEditItem.wood;
      this.kilo = this.currentEditItem.kilo.toString();
      this.volume = this.currentEditItem.volume.toString();
      this.moisture = this.currentEditItem.moisture.toString();
      this.unit = this.currentEditItem.unit;
    }

    this.updateValue();
  },
  computed: {
    isEditMode() {
      return (
        this.currentEditItem &&
        this.$route.query.mode &&
        this.$route.query.mode === "edit"
      );
    },
    isFormValid() {
      // If no current
      if (!this.current) {
        return false;
      }

      // Are values filled
      const wood = this.current.wood !== null;
      const result = this.current.result > 0;

      // Validation criteria depends on the selected unit
      if (this.unit === "weight") {
        return result;
      } else {
        return result && wood;
      }
    },
  },
  methods: {
    updateValue() {
      const wood = this.wood;

      let moisture = parseFloat(this.moisture.replace(",", "."));
      moisture = isNaN(moisture) ? this.defaultMoisture : moisture;

      const volume = parseFloat(this.volume.replace(",", ".")) || 0;
      const volumeResult = wood ? wood.weight * volume : 0;

      const kilo = parseFloat(this.kilo.replace(",", ".")) || 0;

      const result = this.unit === "volume" ? volumeResult : kilo;

      this.current = {
        wood: wood,
        moisture: moisture,
        volume: this.unit === "volume" ? volume : 0,
        kilo: this.unit === "weight" ? kilo : 0,
        result: result,
        unit: this.unit,
      };

      if (this.isEditMode) {
        this.current.id = this.currentEditItem.id;
      }
    },
    unitToggle(unit) {
      this.unit = unit;
      this.updateValue();
    },
    okClick() {
      if (this.isFormValid) {
        if (this.isEditMode) {
          this.$emit("save-wood", this.current);
        } else {
          this.$emit("add-wood", this.current);
        }
      }
    },
    label({ name }) {
      return name;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.wood-add-form {
  margin: 40px 0;
}
.wood-add-form__row {
  display: flex;
  padding: 10px 0;
}
.wood-add-form__col {
  width: 100%;
  position: relative;
}
.wood-add-form__col.wood-add-form__col--tooltip {
  padding-right: 40px;
}
.wood-add-form__header {
  text-align: center;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.wood-add-form__moisture-note {
  color: #fff;
  font-size: 14px;
}
.multiselect__tags {
  border: 1px solid #00b17a;
  border-radius: 4px;
  min-height: 58px;
}
.multiselect__single {
  margin-top: 10px;
  font-size: 18px;
}
.multiselect__input {
  margin-top: 10px;
  font-size: 18px;
}
.multiselect__option.multiselect__option--selected {
  color: #fff;
}
.multiselect__select {
  height: 50px;
}
.multiselect__placeholder {
  margin: 8px 3px;
  font-size: 18px;
  color: #757575;
}
.multiselect__option--selected {
  background-color: #00b17a !important;
}
.multiselect-selected .name {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}
.multiselect-selected .species {
  text-transform: uppercase;
  font-size: 12px;
}
.popper {
  width: 300px;
  text-align: left;
  padding: 18px 22px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}
.popper-trigger {
  right: 0;
  top: 8px;
  display: block;
  position: absolute;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  color: #00b17a;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}
</style>
