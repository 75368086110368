<template>
  <div class="examples">
    <router-link to="/"
      ><back-button :label="dictionary.buttonback"
    /></router-link>

    <div class="examples__content">
      <h2 class="examples__header">{{ dictionary.examplesheader }}</h2>
      <examples-list :examples="examples" @select-example="selectExample" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BackButton from "@/components/BackButton";
import ExamplesList from "@/components/ExamplesList";

export default {
  name: "Examples",
  components: { BackButton, ExamplesList },
  computed: {
    ...mapState(["dictionary", "examples"]),
  },
  methods: {
    selectExample(example) {
      this.setExample(example);
      this.$router.push({ path: "examples", query: { mode: "result" } });
    },
    ...mapActions(["setExample"]),
  },
};
</script>
<style>
.examples {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: calc(100vh - env(safe-area-inset-bottom));
  overflow: hidden;
  background-color: #f2fffc;
  background-image: url("/images/bg-trees.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.examples__header {
  text-align: center;
  width: 100%;
  margin-bottom: 5vw;
  margin-top: 0;
  color: #00b17a;
}
.examples__content {
  position: absolute;
  top: 60px;
  width: 100%;
  height: calc(100vh - env(safe-area-inset-bottom) - 60px);
  padding: 5vw 20px 100px 20px;
  overflow-y: auto;
}
</style>
