var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"unit-toggle"},[_c('div',{class:{
      'unit-toggle-button': true,
      'unit-toggle-button--weight': true,
      'unit-toggle-button--active': _vm.active === 'weight',
    },on:{"click":function($event){return _vm.$emit('toggle', 'weight')}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 'weight'),expression:"active === 'weight'"}],staticClass:"unit-toggle-button__image",attrs:{"src":"images/icon-weight.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.active !== 'weight'),expression:"active !== 'weight'"}],staticClass:"unit-toggle-button__image",attrs:{"src":"images/icon-weight--white.svg"}}),_c('span',{staticClass:"unit-toggle-button__label"},[_vm._v(_vm._s(_vm.dictionary.weight))])]),_c('div',{class:{
      'unit-toggle-button': true,
      'unit-toggle-button--volume': true,
      'unit-toggle-button--active': _vm.active === 'volume',
    },on:{"click":function($event){return _vm.$emit('toggle', 'volume')}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 'volume'),expression:"active === 'volume'"}],staticClass:"unit-toggle-button__image",attrs:{"src":"images/icon-cube.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.active !== 'volume'),expression:"active !== 'volume'"}],staticClass:"unit-toggle-button__image",attrs:{"src":"images/icon-cube--white.svg"}}),_c('span',{staticClass:"unit-toggle-button__label"},[_vm._v(_vm._s(_vm.dictionary.volume))])])])}
var staticRenderFns = []

export { render, staticRenderFns }