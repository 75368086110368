<template>
  <ul class="examples-list">
    <example-item
      v-for="item in examples"
      :key="item.label"
      :item="item"
      @click="$emit('select-example', item)"
    />
  </ul>
</template>

<script>
import ExampleItem from "@/components/ExampleItem";

export default {
  name: "ExamplesList",
  components: { ExampleItem },
  props: {
    examples: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>
<style>
.examples-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
</style>
