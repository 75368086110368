// Return kilos formatted for locale with optional postfix
function _formatNumberWithPostfix(value, locale, postfix) {
  return postfix && postfix.length > 0
    ? `${value.toLocaleString(locale)} ${postfix}`
    : value.toLocaleString(locale);
}

module.exports = {
  formatNumberWithPostfix: _formatNumberWithPostfix,
};
