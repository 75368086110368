<template>
  <div class="add-button">
    <div class="add-button__col">
      <img class="add-button__icon" src="/images/icon-plus--green.svg" />
    </div>
    <div class="add-button__col">
      <span class="add-button__label">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeButton",
  props: {
    label: { type: String, default: "" },
  },
};
</script>
<style>
.add-button {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #00b17a;
  border: 2px dashed #00b17a;
  border-radius: 31px;
  height: 62px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  margin: 0 auto;
  cursor: pointer;
}
.add-button__icon {
  margin: 0 20px 0 0;
}
</style>
