<template>
  <div class="wood-list">
    <h2
      v-if="items.length === 0"
      class="wood-list__header wood-list__header--empty"
    >
      {{ dictionary.woodheaderempty }}
    </h2>
    <h2 v-if="items.length > 0" class="wood-list__header">
      {{ dictionary.woodheader }}
    </h2>

    <ul class="wood-list-items">
      <li
        v-for="(item, index) in items"
        :key="`wood-list-item-${index}`"
        class="wood-list-item"
        @click="$emit('item-click', item)"
      >
        <div class="wood-list-item__name">{{ itemName(item) }}</div>
        <div class="wood-list-item__kilo">
          {{ formatNumber(item.result, locale, dictionary.kilo) }}
        </div>
        <div class="wood-list-item__moisture">{{ `${item.moisture}%` }}</div>
        <img
          class="wood-list-item__close"
          src="/images/icon-close--green.svg"
          @click.stop="$emit('remove-item', item)"
        />
      </li>
    </ul>

    <router-link to="/wood?mode=add">
      <add-button :label="dictionary.woodbuttonadd" />
    </router-link>
  </div>
</template>

<script>
import { formatNumberWithPostfix } from "@/utils/formatter";
import AddButton from "@/components/AddButton";

export default {
  name: "WoodList",
  components: { AddButton },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    locale: {
      type: String,
      default: "da-DK",
    },
    dictionary: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    itemName(item) {
      return item.wood ? item.wood.name : this.dictionary.unknownwood;
    },
    formatNumber(value, locale, postfix) {
      return formatNumberWithPostfix(value, locale, postfix);
    },
  },
};
</script>

<style>
.wood-list {
  margin-bottom: 40px;
}
.wood-list__header {
  text-transform: uppercase;
  font-size: 22px;
  color: #00b17a;
  text-align: center;
  margin: 0 0 40px 0;
}
.wood-list__header--empty {
  margin-bottom: 100px;
}
.wood-list-items {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wood-list-item {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  padding: 13px 60px 13px 30px;
  width: 100%;
  max-width: 340px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
}
.wood-list-item__name {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 6px;
}
.wood-list-item__kilo {
  width: 100px;
}
.wood-list-item__close {
  position: absolute;
  right: 19px;
  top: 20px;
  padding: 5px;
  transition: transform 0.2s ease;
}
.wood-list-item__close:hover {
  transform: scale(1.3);
}
</style>
