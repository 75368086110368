<template>
  <div class="wood">
    <router-link to="/"
      ><back-button :label="dictionary.buttonback"
    /></router-link>

    <div class="wood__content">
      <wood-list
        :dictionary="dictionary"
        :items="items"
        @remove-item="removeItem"
        @item-click="itemClick"
      />

      <router-link to="/wood?mode=result">
        <simple-button v-if="hasItems" :label="dictionary.showresult" invert />
      </router-link>
    </div>

    <transition name="slide-up-down">
      <wood-add-panel
        v-if="isAddPanelVisible"
        :dictionary="dictionary"
        :woods="woods"
        :moisture="settings.defaultmoisture"
        :currentEditItem="currentEditItem"
        @add-wood="addItem"
        @save-wood="saveItem"
      />
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BackButton from "@/components/BackButton";
import SimpleButton from "@/components/SimpleButton";
import WoodList from "@/components/WoodList";
import WoodAddPanel from "@/components/WoodAddPanel";

export default {
  name: "Wood",
  components: { BackButton, SimpleButton, WoodList, WoodAddPanel },
  computed: {
    isAddPanelVisible() {
      return (
        this.$route.query.mode &&
        (this.$route.query.mode === "add" || this.$route.query.mode === "edit")
      );
    },
    hasItems() {
      return this.items.length > 0;
    },
    ...mapState([
      "dictionary",
      "woods",
      "settings",
      "items",
      "currentEditItem",
    ]),
  },
  mounted() {
    this.clearExample();
  },
  methods: {
    itemClick(item) {
      this.editItem(item);
      this.$router.push({ path: "wood", query: { mode: "edit" } });
    },
    ...mapActions([
      "addItem",
      "removeItem",
      "editItem",
      "saveItem",
      "clearExample",
    ]),
  },
};
</script>
<style>
.wood {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: calc(100vh - env(safe-area-inset-bottom));
  overflow: hidden;
  background-color: #f2fffc;
  background-image: url("/images/bg-trees.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.wood__content {
  position: absolute;
  top: 60px;
  width: 100%;
  height: calc(100vh - 60px);
  padding: 5vw 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
</style>
