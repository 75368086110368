<template>
  <div class="fact">
    <div class="fact-wrapper">
      <img class="fact__icon" :src="`images/icon-${icon}.svg`" /><slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Fact",
  props: {
    icon: String,
  },
};
</script>
<style>
.fact {
  padding: 50px 15px 15px 15px;
}
.fact-wrapper {
  position: relative;
  max-width: 621px;
  color: #fff;
  border: 2px solid #fff;
  padding: 30px;
  font-size: 18px;
  line-height: 25px;
  margin: 0 auto;
}
.fact__icon {
  max-width: 80px;
  margin: 0 auto;
  display: block;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .fact-wrapper {
    padding: 30px 40px 30px 160px;
  }
  .fact__icon {
    position: absolute;
    top: 36px;
    left: 45px;
    width: 60px;
    height: auto;
  }
}
</style>
