<template>
  <div :class="classes">
    <div class="home-button__col">
      <img class="home-button__icon" :src="iconUrl" />
    </div>
    <div class="home-button__col">
      <span class="home-button__label">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeButton",
  props: {
    label: { type: String, default: "" },
    icon: { type: String, default: "ruler--white" },
    invert: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return {
        "home-button": true,
        "home-button--invert": this.invert,
      };
    },
    iconUrl() {
      return `/images/icon-${this.icon}.svg`;
    },
  },
};
</script>
<style>
.home-button {
  display: block;
  background-color: #fff;
  font-size: 18px;
  font-weight: bold;
  color: #00b17a;
  margin: 10px 0;
  padding: 0 20px 0 20px;
  border-radius: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  height: 78px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 300px;
}
.home-button.home-button--invert {
  background-color: #00b17a;
  color: #fff;
}
.home-button__icon {
  margin: 0 20px 0 0;
}
@media screen and (min-width: 768px) {
  .home-button {
    width: 300px;
    margin: 0 10px;
  }
}
</style>
