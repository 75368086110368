<template>
  <div class="unit-toggle">
    <div
      :class="{
        'unit-toggle-button': true,
        'unit-toggle-button--weight': true,
        'unit-toggle-button--active': active === 'weight',
      }"
      @click="$emit('toggle', 'weight')"
    >
      <img
        v-show="active === 'weight'"
        class="unit-toggle-button__image"
        src="images/icon-weight.svg"
      /><img
        v-show="active !== 'weight'"
        class="unit-toggle-button__image"
        src="images/icon-weight--white.svg"
      /><span class="unit-toggle-button__label">{{ dictionary.weight }}</span>
    </div>
    <div
      :class="{
        'unit-toggle-button': true,
        'unit-toggle-button--volume': true,
        'unit-toggle-button--active': active === 'volume',
      }"
      @click="$emit('toggle', 'volume')"
    >
      <img
        v-show="active === 'volume'"
        class="unit-toggle-button__image"
        src="images/icon-cube.svg"
      /><img
        v-show="active !== 'volume'"
        class="unit-toggle-button__image"
        src="images/icon-cube--white.svg"
      /><span class="unit-toggle-button__label">{{ dictionary.volume }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnitToggle",
  props: {
    dictionary: {
      type: Object,
      default: function () {
        return {};
      },
    },
    active: {
      type: String,
      default: "weight",
    },
  },
};
</script>

<style>
.unit-toggle {
  display: flex;
  width: 100%;
  padding-right: 40px;
}
.unit-toggle-button {
  width: 50%;
  border: 2px solid #fff;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.unit-toggle-button.unit-toggle-button--active {
  background-color: #fff;
  color: #00b17a;
}
.unit-toggle-button.unit-toggle-button--weight {
  border-right: none;
  border-radius: 10px 0 0 10px;
}
.unit-toggle-button.unit-toggle-button--volume {
  border-left: none;
  border-radius: 0 10px 10px 0;
}
.unit-toggle-button__image {
  margin-right: 10px;
}
</style>
